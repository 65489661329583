import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import RoutesList from "./RoutesList";
import { URL_FORUM, URL_NEWS, URL_NEWS_DETAILS, URL_SHOP, URL_DOWNLOAD, URL_HOME } from "Helpers/Paths";
import Home from 'Components/Home/Home';
import News from 'Components/News/News';
import Forum from 'Components/Forum/Forum';
import NewsDetails from 'Components/News/NewsDetails/NewsDetails';
import Download from "Components/Download/Download";
import PageNotFound from 'Components/PageNotFound';

const LANG_REGEX = "en|de|fr|it|ru";

function Routes({ lang }) {
    const { REACT_APP_DOWNLOAD_URL } = process.env;
    return (
        <Switch>
            <Route exact path={`/:lang(${LANG_REGEX})`} component={Home} />
            <Route exact path={URL_DOWNLOAD} component={Download} />
            <Route exact path={URL_HOME} component={Home} />
            <Route exact path={URL_NEWS} component={News} />
            <Route exact path={URL_SHOP} render={() => window.location.replace(`${REACT_APP_DOWNLOAD_URL}/${lang}/store/neverwinter/list`)} />
            <Route exact path={URL_FORUM} component={Forum} />
            <Route exact path={URL_NEWS_DETAILS + ":newsId"} component={NewsDetails} />
            <Route exact path="/">
                <Redirect to={`/${lang}/`} />
            </Route>

            <Route path={"*"} component={PageNotFound} />
            {/* <ProtectedRoute> */}
            {
                RoutesList.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                    />
                ))
            }
            {/* </ProtectedRoute> */}

        </Switch>
    );
}

export default Routes;
